import * as _ from '@proftit/lodash';
import template from './contact-higlights-prop.html';
import ControllerBase from '~/source/common/controllers/base';
import CustomersService from '~/source/contact/common/services/customers';
import { TradingHighlights } from '~/source/common/models/trading-highlights';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { TradingPropHighlights } from '~/source/common/models/trading-prop-highlights';

class Controller extends ControllerBase {
  // Bindings
  customerId: number;

  highlights: TradingHighlights;

  /*@ngInject*/
  constructor(readonly customersService: () => CustomersService) {
    super();
  }

  $onInit() {
    this.initComponent();
  }

  async initComponent() {
    this.highlights = (this.customersService()
      .setConfig({ blockUiRef: 'highlights' })
      .getStatsResource(this.customerId, 'trading')
      .expand(['tradeReputation', 'maxDepositCurrency'])
      .getOneWithQuery<IElementRestNg<TradingHighlights>>() as any).$object;
  }

  /**
   * Returns highest number of assets in various groups (winning, losing and top)
   *
   * @returns {number}
   */
  get maxQty() {
    // received from server, if empty - return 0
    if (_.isEmpty(this.highlights)) {
      return 0;
    }
    return Math.max(
      this.highlights.winningAssets.length,
      this.highlights.losingAssets.length,
      this.highlights.topAssets.length,
    );
  }

  /**
   * Returns an array with the length of the rows needed to hold the top assets list.
   * so we could ng-repeat this num and create the rows background
   * @returns {Array}
   */
  getTopAssetRowsAr() {
    // Calculate number of rows needed - the largest between winning and losing assets num
    const rowsNum = this.maxQty;
    // create an array the size of this range
    return _.range(0, rowsNum);
  }

  /**
   * Returns the total earning
   * @returns {number}
   */
  getTotalEarning() {
    const withdrawalTotal = this.highlights.withdrawalTotal
      ? this.highlights.withdrawalTotal
      : 0;
    const withdrawableBalance = this.highlights.withdrawableBalance
      ? this.highlights.withdrawableBalance
      : 0;
    return withdrawalTotal + withdrawableBalance;
  }

  /**
   * Returns the purchases AVG
   * @returns {number}
   */
  getPurchaseAvg() {
    const approvedDepositsCount = this.highlights.approvedDepositsCount
      ? this.highlights.approvedDepositsCount
      : 0;
    const depositTotal = this.highlights.depositTotal
      ? this.highlights.depositTotal
      : 0;
    return approvedDepositsCount === 0
      ? 0
      : depositTotal / approvedDepositsCount;
  }

  /**
   * Returns true if there is at least one winning or losing asset
   * @returns {boolean}
   */
  hasTopAssets() {
    if (_.isEmpty(this.highlights)) {
      return false;
    }

    return !!this.maxQty;
  }

  //todo: implement this with service method then backend is ready
  fetchHighlights(): Promise<TradingPropHighlights> {
    const demo: TradingPropHighlights = {
      tradeReputation: { code: 'Normal', id: 1, name: 'Normal' },
      totalTrades: 20,
      totalEarning: 1000,
      totalPurchaseAmount: 2000,
      highestChallengePurchase: 3000,
      averageChallengePurchase: 4000,
      winningAssets: [],
      losingAssets: [
        {
          id: 1184,
          code: 'usd/jpy',
          isTradeable: 1,
          tailDigits: 3,
          group: 'currency',
          lastUpdate: '2024-08-19 13:16:01',
          created_at: '2024-08-19 13:16:01',
          updated_at: '2024-08-19 13:16:01',
        },
        {
          id: 1186,
          code: 'OIL',
          isTradeable: 1,
          tailDigits: 3,
          group: 'commodity',
          lastUpdate: '2024-08-19 13:16:01',
          created_at: '2024-08-19 13:16:01',
          updated_at: '2024-08-19 13:16:01',
        },
      ],
      topAssets: [
        {
          id: 1185,
          code: 'gbp/usd',
          isTradeable: 1,
          tailDigits: 3,
          group: 'currency',
          lastUpdate: '2024-08-19 13:16:01',
          created_at: '2024-08-19 13:16:01',
          updated_at: '2024-08-19 13:16:01',
        },
        {
          id: 1186,
          code: 'GOLD',
          isTradeable: 1,
          tailDigits: 3,
          group: 'commodity',
          lastUpdate: '2024-08-19 13:16:01',
          created_at: '2024-08-19 13:16:01',
          updated_at: '2024-08-19 13:16:01',
        },
      ],
    };

    return Promise.resolve(demo);
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    customerId: '<',
  },
};
