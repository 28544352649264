import template from './contacts-stats.html';
import BaseController from '~/source/common/controllers/base';
import angular, { IScope } from 'angular';
import CustomerStats from '~/source/contact/common/services/stats.service';
import { PlatformType } from '@proftit/crm.api.models.entities';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';

class ContactsStatsController extends BaseController {
  stats;

  /*@ngInject*/
  constructor(readonly $scope: IScope) {
    super();
  }
}

export default {
  bindings: {
    stats: '<',
    platformType: '<',
  },
  template,
  controller: ContactsStatsController,
};
