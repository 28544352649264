import * as rx from '@proftit/rxjs';
import log from 'loglevel';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { Challenge, Customer } from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';
import template from './challenge-account-container.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import { StateParams } from '@uirouter/core';
import { TokensService } from '~/source/auth/services/tokens';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import ChallengeSocketService from '~/source/contact/contact-page/prop-account/challenge-account-container/challenge-socket.service';

const styles = require('./challenge-account-container.scss');

export class ChallengeAccountContainerController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  customer: Customer;
  challenges$ = new rx.BehaviorSubject<Challenge[]>([]);
  activeChallengeId$ = new rx.BehaviorSubject<number>(null);
  customerId: number;
  user$ = this.streamUser();

  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );

  /*@ngInject*/
  constructor(
    readonly customersService: () => CustomersService,
    readonly $stateParams: StateParams,
    readonly tokensService: TokensService,
    readonly challengeSocketService: ChallengeSocketService,
  ) {
    useStreams(
      [
        this.customer$,
        this.streamCustomerChallenges(),
        this.streamSetActiveChallengeOnChallengesOnFirst(),
        this.streamNewChallengeToCrmSocket(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamUser() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.filter((isInit) => isInit),
      rx.map(() => this.tokensService.getCachedUser()),
      shareReplayRefOne(),
    )(null);
  }

  streamNewChallengeToCrmSocket() {
    return this.user$.pipe(
      rx.switchMap((user) => {
        const crmChannelName = `user.${user.id}.customer.${this.customer.id}.CustomerChallenge.new`;
        return observeChannel(this.challengeSocketService, crmChannelName).pipe(
          rx
            .map(() => {
              return rx.obs
                .from(this.fetchCustomerChallenges(this.customer.id))
                .pipe(
                  rx.tap((data) => {
                    //TODO trigger streamCustomerChallenges stream to call fetch again
                  }),
                );
            })
            .bind(this),
        );
      }),
      rx.catchError((err) => {
        log.error('Error in observing CRM new challenges', err);
        return rx.obs.EMPTY;
      }),
    );
  }

  get activeChallengeId() {
    return this.activeChallengeId$.value;
  }

  set activeChallengeId(value) {
    if (value) {
      this.activeChallengeId$.next(value);
      const element = document.querySelector('#active-challenge-tabset');
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 1000);
    }
  }

  streamSetActiveChallengeOnChallengesOnFirst() {
    return rx.pipe(
      () => this.challenges$,
      rx.map((challenges) => {
        if (challenges.length === 0) {
          return null;
        }
        if (_.isNil(this.$stateParams.challengeId)) {
          return challenges[0].id;
        }
        const challengeId = parseInt(this.$stateParams.challengeId, 10);
        if (challenges.find((challenge) => challenge.id === challengeId)) {
          return challengeId;
        }
        return null;
      }),
      rx.tap((activeId) => {
        this.activeChallengeId = activeId;
      }),
      rx.map(() => rx.obs.EMPTY),
    )(null);
  }

  streamCustomerChallenges() {
    return rx.pipe(
      () => this.customer$,
      rx.startWith(null),
      rx.pairwise(),
      rx.filter(([prev, curr]) => prev?.id !== curr?.id),
      rx.switchMap(([a, customer]) => {
        if (_.isNil(customer)) {
          return rx.obs.of([]);
        }
        this.customerId = customer.id;
        return rx.obs.from(this.fetchCustomerChallenges(customer.id)).pipe(
          rx.catchError(() => {
            console.error('error fetching challenges for customer:', customer);
            return rx.obs.of([]);
          }),
        );
      }),
      rx.tap((data) => {
        this.challenges$.next(data);
        if (data && data.length > 0) {
          this.activeChallengeId = data[0].id;
        }
      }),
    )(null);
  }

  async fetchCustomerChallenges(id: number): Promise<any[]> {
    const data = await this.customersService().getCustomerChallenges(id);
    return data.plain();
  }
}

export const ChallengeAccountContainerComponent = {
  template,
  controller: ChallengeAccountContainerController,
  bindings: {
    customer: '<',
    showAddAccountButton: '<',
    createAccount: '&',
  },
};
