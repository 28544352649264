import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
const styles = require('./challenge-currency-balance.component.scss');

export class CurrencyBalanceFieldController {
  styles = styles;
  settings: any;
  showFee: boolean;
  isRequired: boolean;
  onUpdate: () => void;

  /*@ngInject */
  constructor() {}
  lifecycles = observeComponentLifecycles(this);

  $onInit() {}
  $onDestroy() {}
  $onChanges() {}
  onBalanceChange() {
    if (this.settings.accountBalance === null) {
      this.settings.entryFee = null;
    }
    this.onUpdate();
  }
}

export const CurrencyBalanceFieldComponent = {
  template: require('./currency-balance-field.component.html'),
  controller: CurrencyBalanceFieldController,
  bindings: {
    settings: '<',
    onUpdate: '&',
    showFee: '<',
  },
};
