import { Permissions } from '~/source/common/models/permission-structure';

export const communicationsReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          communicationDate: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'reports.titles.COMMUNICATION_DATE',
        field: 'communicationDate',
        fieldName: 'communicationDate',
        fieldFormat: '{{ communicationDate }}',
        reportFields: ['communicationDate'],
        filterable: true,
        sortable: 'communicationDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMAIL',
        field: 'email',
        fieldName: 'email',
        fieldFormat: '{{ email }}',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'PHONE',
        field: 'phone',
        fieldName: 'phone',
        fieldFormat: '{{ phone }}',
        reportFields: ['phone'],
        filterable: false,
        sortable: 'phone',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REGISTRATION_DATE',
        field: 'registrationDate',
        fieldName: 'registrationDate',
        fieldFormat: '{{ registrationDate }}',
        reportFields: ['registrationDate'],
        filterable: true,
        sortable: 'registrationDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.COMMUNICATION_BY',
        field: 'communicationBy',
        fieldName: 'communicationBy',
        fieldFormat: '{{ communicationBy }}',
        reportFields: ['communicationBy'],
        filterable: true,
        sortable: 'communicationBy',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.COMMUNICATION_STATUS',
        field: 'reportsCommunicationStatus',
        fieldName: 'communicationStatus',
        fieldFormat: '{{ communicationStatus }}',
        reportFields: ['communicationStatus'],
        filterable: true,
        sortable: 'communicationStatus',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.COMMUNICATION_TYPE',
        field: 'reportsCommunicationType',
        fieldName: 'communicationType',
        fieldFormat: '{{ communicationType }}',
        reportFields: ['communicationType'],
        filterable: true,
        sortable: 'communicationType',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.COMMUNICATION_SUBJECT',
        field: 'reportsCommunicationSubject',
        fieldName: 'customerCommunicationSubject',
        fieldFormat: '{{ customerCommunicationSubject }}',
        reportFields: ['customerCommunicationSubject'],
        filterable: true,
        sortable: 'customerCommunicationSubject',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DETAILS',
        field: 'details',
        fieldName: 'details',
        fieldFormat: '{{ details }}',
        reportFields: ['details'],
        filterable: false,
        sortable: 'details',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_CALL_DATE',
        field: 'lastCallDate',
        fieldName: 'lastCallDate',
        fieldFormat: '{{ lastCallDate }}',
        reportFields: ['lastCallDate'],
        filterable: true,
        sortable: 'lastCallDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_COMMENT_DATE',
        field: 'firstCommunicationDate',
        fieldName: 'firstCommunicationDate',
        fieldFormat: '{{ firstCommunicationDate }}',
        reportFields: ['firstCommunicationDate'],
        filterable: true,
        sortable: 'firstCommunicationDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_COMMUNICATION_USER',
        field: 'firstCommunicationUser',
        fieldName: 'firstCommunicationUser',
        fieldFormat: '{{ firstCommunicationUser }}',
        reportFields: ['firstCommunicationUser'],
        filterable: true,
        sortable: 'firstCommunicationUser',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.CUSTOMER_STATUS',
        field: 'customerStatusName',
        fieldName: 'customerStatusName',
        fieldFormat: '{{ customerStatusName }}',
        reportFields: ['customerStatusName'],
        filterable: true,
        sortable: 'customerStatusName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMPLOYEE',
        field: 'username',
        fieldName: 'username',
        fieldFormat: '{{ username }}',
        reportFields: ['username'],
        filterable: true,
        sortable: 'username',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.CALLERS_COUNT',
        field: 'callersCount',
        fieldName: 'callersCount',
        fieldFormat: '{{ callersCount }}',
        reportFields: ['callersCount'],
        filterable: true,
        sortable: 'callersCount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'productName',
        fieldName: 'productName',
        fieldFormat: '{{ productName }}',
        reportFields: ['productName'],
        filterable: false,
        sortable: 'productName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.VERIFIED_STATUS',
        field: 'reportsCustomerComplianceStatus',
        fieldName: 'customerComplianceStatus',
        fieldFormat: '{{ customerComplianceStatus }}',
        reportFields: ['customerComplianceStatus'],
        filterable: true,
        sortable: 'customerComplianceStatus',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.TOTAL_PURCHASES',
        field: 'deposit',
        fieldName: 'deposit',
        fieldFormat: '{{ deposit | number : 2}}',
        reportFields: ['deposit'],
        filterable: true,
        sortable: 'deposit',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.FOLLOW_UP',
        field: 'followUp',
        fieldName: 'followUp',
        fieldFormat: '{{ followUp }}',
        reportFields: ['followUp'],
        filterable: true,
        sortable: 'followUp',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'filters.LAST_LOGIN_DATE',
        field: 'lastLoginDate',
        fieldName: 'lastLoginDate',
        fieldFormat: '{{ lastLoginDate }}',
        reportFields: ['lastLoginDate'],
        filterable: true,
        sortable: 'lastLoginDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_COMMUNICAITON_MINUTES_COUNT',
        field: 'minutesToFirstCommunication',
        fieldName: 'minutesToFirstCommunication',
        fieldFormat: '{{ minutesToFirstCommunication | number : 0}}',
        reportFields: ['minutesToFirstCommunication'],
        filterable: true,
        sortable: 'minutesToFirstCommunication',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.BALANCE',
        field: 'balance',
        fieldName: 'balance',
        fieldFormat: '{{ balance | number : 2}}',
        reportFields: ['balance'],
        filterable: true,
        sortable: 'balance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'bonus.BONUS',
        field: 'bonus',
        fieldName: 'bonus',
        fieldFormat: '{{ bonus }}',
        reportFields: ['bonus'],
        filterable: true,
        sortable: 'bonus',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.CALLS_COUNT',
        field: 'callsCount',
        fieldName: 'callsCount',
        fieldFormat: '{{ callsCount }}',
        reportFields: ['callsCount'],
        filterable: true,
        sortable: 'callsCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'DEPARTMENT',
        field: 'reportsDepartment',
        fieldName: 'department',
        fieldFormat: '{{ department }}',
        reportFields: ['department'],
        filterable: true,
        sortable: 'department',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.EQUITY',
        field: 'equity',
        fieldName: 'equity',
        fieldFormat: '{{ equity | number : 2}}',
        reportFields: ['equity'],
        filterable: true,
        sortable: 'equity',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_COMMUNICATION_ID',
        field: 'firstCommunicationId',
        fieldName: 'firstCommunicationId',
        fieldFormat: '{{ firstCommunicationId }}',
        reportFields: ['firstCommunicationId'],
        filterable: false,
        sortable: 'firstCommunicationId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'marketing.FTP_AMOUNT',
        field: 'ftdAmount',
        fieldName: 'ftdAmount',
        fieldFormat: '{{ ftdAmount | number : 2 }}',
        reportFields: ['ftdAmount'],
        filterable: true,
        sortable: 'ftdAmount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.FTP_AMOUNT_BASIC',
        field: 'ftdAmountBasic',
        fieldName: 'ftdAmountBasic',
        fieldFormat: '{{ ftdAmountBasic | baseCurrencyFormat : 2 : true}}',
        reportFields: ['ftdAmountBasic'],
        filterable: true,
        sortable: 'ftdAmountBasic',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.FTP_DAYS_COUNT',
        field: 'ftdDaysCount',
        fieldName: 'ftdDaysCount',
        fieldFormat: '{{ ftdDaysCount }}',
        reportFields: ['ftdDaysCount'],
        filterable: true,
        sortable: 'ftdDaysCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.GENDER',
        field: 'gender',
        fieldName: 'gender',
        fieldFormat: '{{ gender }}',
        reportFields: ['gender'],
        filterable: false,
        sortable: 'gender',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.REAL',
        field: 'reportsReal',
        fieldName: 'real',
        fieldFormat: '{{ real }}',
        reportFields: ['real'],
        filterable: true,
        sortable: 'real',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.LANGUAGE',
        field: 'reportsLanguage',
        fieldName: 'language',
        fieldFormat: '{{ language }}',
        reportFields: ['language'],
        filterable: true,
        sortable: 'language',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'filters.LAST_POSITION_DATE',
        field: 'lastPositionDate',
        fieldName: 'lastPositionDate',
        fieldFormat: '{{ lastPositionDate }}',
        reportFields: ['lastPositionDate'],
        filterable: true,
        sortable: 'lastPositionDate',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'accounting.OPEN_POSITIONS',
        field: 'openPositions',
        fieldName: 'openPositions',
        fieldFormat: '{{ openPositions }}',
        reportFields: ['openPositions'],
        filterable: true,
        sortable: 'openPositions',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'marketing.table.PNL',
        field: 'pnl',
        fieldName: 'pnl',
        fieldFormat: '{{ pnl | number : 2}}',
        reportFields: ['pnl'],
        filterable: true,
        sortable: 'pnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.RECORDING_FILE',
        field: 'recordingFile',
        fieldName: 'recordingFile',
        fieldFormat: '{{ recordingFile }}',
        reportFields: ['recordingFile'],
        filterable: false,
        sortable: 'recordingFile',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.REGISTRATION_DATE_COUNT',
        field: 'registrationDateCount',
        fieldName: 'registrationDateCount',
        fieldFormat: '{{ registrationDateCount }}',
        reportFields: ['registrationDateCount'],
        filterable: true,
        sortable: 'registrationDateCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.TRADES_COUNT',
        field: 'tradeCount',
        fieldName: 'tradeCount',
        fieldFormat: '{{ tradeCount }}',
        reportFields: ['tradeCount'],
        filterable: true,
        sortable: 'tradeCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.LAST_UPDATED_DATE',
        field: 'updatedAt',
        fieldName: 'updatedAt',
        fieldFormat: '{{ updatedAt }}',
        reportFields: ['updatedAt'],
        filterable: true,
        sortable: 'updatedAt',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
