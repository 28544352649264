import * as _ from '@proftit/lodash';
import template from './withdrawal-audit-log-popup.html';
import TablePopupController from '~/source/common/components/table/table-popup';
import withdrawalAuditSettings from './withdrawal-audit-log-popup-settings.json';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';

class Controller extends TablePopupController {
  static $inject = ['customersService', ...TablePopupController.$inject];

  settings = _.cloneDeep(withdrawalAuditSettings);
  cols = [...this.settings.tableColumns];
  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  customer: Customer;

  $onInit() {
    super.$onInit();
    this.cols = this.cols.map((item) =>
      item.transform
        ? { ...item, title: this.transformLabel(item.title) }
        : item,
    );
  }

  isProp() {
    return this.customer.brand.platformType.code === PlatformTypeCode.Prop;
  }

  /**
   * Transform Prop label
   *
   * @returns string
   */
  transformLabel(label) {
    return this.isProp() ? label + '_PROP' : label;
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings.popupTable.ngTable;
  }

  get tableKey() {
    return 'withdrawalAuditLogPopup';
  }

  onResolveChange(resolve) {
    if (_.isNil(resolve)) {
      this.customer = null;
      return;
    }
    this.customer = resolve.customer;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.dataServiceInstance
      .getWithdrawalAuditLogResource(this.customer.id)
      .sort({ requestedAt: 'desc' });
  }
}

export const WithdrawalAuditLogPopupTable = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
